<template>
  <v-container class="px-10" fluid>
    <v-popup-loading :isLoading="isLoadingBranch" />
    <v-dialog-confirm
      :show="isDelete"
      title="Remove Confirmation"
      :subTitle="subTitle"
      :close="closeDialog"
      headline-color="black"
      buttonText="Remove"
      :confirm="confirmDialog"
      icon=""
    ></v-dialog-confirm>
    <snack-bar v-model="isCreateSuccess" :content="snackbarSuccess"></snack-bar>
    <snack-bar
      v-model="isCreateFail"
      :content="snackbarFail"
      type="error"
    ></snack-bar>
    <preview
      :show="isPreview"
      :close-preview="setShowPreview"
      :data-object="bannerObject"
    />
    <edit-order-dialog
      :show="isEditOrder"
      :data="editOrderObject"
      :close="closeEditOrder"
      @save="saveEditOrder"
    ></edit-order-dialog>

    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <div class="d-flex justify-space-between">
          <h3 class="pageHeadline" t-data="page-headline">Slide Banner</h3>
          <v-btn
            id="add-btn"
            v-if="hasPermCreate()"
            large
            color="primary600Color"
            @click="create"
            class="white-text-button button-bold"
            t-data="create-btn"
          >
            <v-icon left>mdi-plus-circle-outline</v-icon>Create Slide Banner
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Data table -->
    <v-card>
      <v-card-title :class="{'mb-0': true, 'pb-0': isSelectedBranch}">
        <v-row>
          <v-col cols="12" sm="4" class="mt-0 pt-0 pb-0">
            <single-selected-branch
              outlined
              required
              dense
              title="Branch"
              :branch="orgList.all"
              :selectedBranch="selectedBranch"
              :syncData="changedSelectedBranch"
              :rule="v => !!v || 'Please select branch'"
            ></single-selected-branch>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title class="pt-0 pb-2" v-if="showSearchAndFilter">
        <v-row>
          <v-col class="pt-0 pb-0" cols="12" sm="4" t-data="search-panel">
            <search
              :search="filters.searchPhase"
              label="Search by Title"
              @input="textSearch"
              @clear="clearSearch()"
            ></search>
          </v-col>
          <v-col class="pt-0 pb-0" cols="12" sm="6"></v-col>
          <v-col
            cols="12"
            sm="2"
            class="d-flex justify-end align-center pt-0 pb-0"
            t-data="filter-panel"
          >
            <filter-dropdown :key="componentKey" @apply="handleApplyFilter"></filter-dropdown>
          </v-col>
        </v-row>
      </v-card-title>
      <v-tabs
        v-if="isSelectedBranch"
        v-model="tab"
        class="status-tab fix-height-tab body-regular"
        color="primary500Color"
        slider-color="primary500Color"
        align-with-title
        @change="fetchByTab"
      >
        <v-tab class="fix-width-tab" v-for="item in tabItems" :key="item">
          {{ item }}
        </v-tab>
        <v-tab-item>
          <v-layout fluid v-resize="onResize">
            <v-data-table
              :loading="isLoading"
              :options.sync="options"
              :headers="table.headers"
              :items="banners"
              :items-per-page="table.pageSize"
              :server-items-length="total"
              :footer-props="{'items-per-page-options': table.itemPerPages}"
              :sort-desc="true"
              :must-sort="false"
              class="elevation-1 custom-table"
              fixed-header
              style="width: 100%"
              :height="
                windowSize.y - heightOptions.header - heightOptions.tableFooter
              "
            >
              <template v-slot:item.content="{item}">
                <tooltip-title :contents="item.contents">
                  <template v-slot:content>
                    <v-list-item-content
                      class="d-inline-block text-truncate"
                      style="width: 180px"
                    >
                      <v-list-item-title>
                        {{ getContentByLang(item.contents, 'th') }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ getContentByLang(item.contents, 'en') }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </tooltip-title>
              </template>
              <template v-slot:item.orgs="{item}">{{
                listBranch(item.orgs)
              }}</template>
              <template v-slot:item.type="{item}">{{
                getType(item.type)
              }}</template>
              <template v-slot:item.is_publish="{item}">
                <div class="d-flex justify-center">
                  <publish-status-chip :is-publish="item.is_publish" />
                </div>
              </template>
              <template v-slot:item.period_start_date="{item}">
                {{
                  !!item.period_start_date
                    ? dateFmt(item.period_start_date)
                    : ''
                }}
              </template>
              <template v-slot:item.period_end_date="{item}">
                {{
                  !!item.period_end_date ? dateFmt(item.period_end_date) : ''
                }}
              </template>
              <template v-slot:item.update_date="{item}">
                {{ !!item.update_date ? dateFmt(item.update_date) : '' }}
              </template>
              <template v-slot:item.update_by_user="{item}">
                <username-status-text :user="item.update_by_user" />
              </template>
              <template v-slot:item.status="{item}">
                <v-switch
                  t-data="status-switch"
                  color="primary200Color"
                  v-model="item.status"
                  :false-value="inActiveStatus"
                  :true-value="activeStatus"
                  :disabled="!hasPermEditByOrgs(item.orgs)"
                  @change="event => handleSwitchStatusChange(event, item.id)"
                  class="mb-0 pb-0 custom-switch"
                ></v-switch>
              </template>
              <template v-slot:item.actions="{item}">
                <v-icon
                  t-data="preview-action"
                  class="px-2 action-icon"
                  @click="actionPreview(item)"
                  >mdi-magnify</v-icon
                >
                <v-icon
                  t-data="edit-action"
                  class="px-2 action-icon"
                  @click="actionEdit(item)"
                  >mdi-pencil-outline</v-icon
                >
                <v-icon
                  t-data="delete-action"
                  class="px-2 action-icon"
                  v-if="hasPermDeleteByOrgs(item.orgs)"
                  @click="actionDelete(item)"
                  >mdi-trash-can-outline</v-icon
                >
                <v-icon
                  v-else
                  t-data="empty-icon"
                  class="px-2 action-icon transparent-icon"
                ></v-icon>
              </template>
            </v-data-table>
          </v-layout>
          <div
            v-if="!isLoading"
            class="table-footer-prepend"
            t-data="total-result-content"
          >
            <div class="vertical-center pl-4">
              <b>{{ total }}</b> Search results
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <sortable-table
            @updateSuccess="showSuccessSnackbar(actionEnumData.STATUS_UPDATED)"
            @updateFail="showFailSnackbar(actionEnumData.STATUS_UPDATED)"
          ></sortable-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>

<script>
import EditOrderDialog from '@/components/EditOrderDialog'
import {mapState, mapActions} from 'vuex'

import {
  convertDate,
  hasPermision,
  searchHandler,
  snackbarSuccessContent,
  snackbarFailContent,
  hasPermissionsByOrgs,
  convertSelectedData,
  swapArrayElements
} from '../../helper/helper'
import Confirm from '../../components/AlertConfirm'
import FilterDropdown from '@/views/banner/FilterDropdown'
import Preview from '@/components/banner/Preview'
import Search from '@/components/Search'
import SnackBar from '@/components/SnackBar'
import TooltipTitle from '@/components/TooltipTitle'
import {actionEnum, tabEnum} from '@/constants/constants'
import {bannerStatusEnum, typeText} from '@/constants/banner'
import SingleSelectedBranch from '@/components/SingleSelectedBranch'
import PopupLoading from '@/components/PopupLoading'
import PublishStatusChip from '@/components/PublishStatusChip'
import UsernameStatusText from '@/components/UsernameStatusText'
import SortableTable from '@/components/banner/SortableTable'

export default {
  created() {
    this.setListOrgInfo()
    this.textSearch()
  },
  mounted() {
    let query = this.$route.query.isCreateSuccess
    let querySnackTitle = this.$route.query.snackTitle

    if (query) {
      this.$router.replace({})
      this.isCreateSuccess = query
      this.snackTitle = querySnackTitle
    }
  },
  beforeRouteLeave(to, from, next) {
    this.resetList()
    next()
  },
  components: {
    EditOrderDialog,
    'v-dialog-confirm': Confirm,
    'v-popup-loading': PopupLoading,
    FilterDropdown,
    Preview,
    PublishStatusChip,
    Search,
    SingleSelectedBranch,
    SnackBar,
    SortableTable,
    TooltipTitle,
    UsernameStatusText
  },
  computed: {
    isSelectedBranch() {
      return this.orgList.selected.length > 0
    },
    showSearchAndFilter() {
      return this.isSelectedBranch && this.tabItems[this.tab] === tabEnum.LIST
    },
    tabItems() {
      return Object.values(tabEnum)
    },
    snackbarSuccess() {
      return snackbarSuccessContent(this.pageName, this.snackTitle)
    },
    snackbarFail() {
      return snackbarFailContent(this.pageName, this.snackTitle)
    },
    ...mapState('banner', [
      'bannerObject',
      'banners',
      'editOrderObject',
      'isEditOrder',
      'isLoading',
      'isLoadingBranch',
      'isPreview',
      'orgList',
      'sortableList',
      'statusTab',
      'total'
    ])
  },
  watch: {
    options: {
      handler() {
        const {sortBy, sortDesc, page, itemsPerPage} = this.options
        let sort
        if (sortBy[0] === 'contents') {
          sort = 'title'
        } else if (sortBy[0] === 'org') {
          sort = 'org_codename'
        } else {
          sort = sortBy[0]
        }
        this.setPage(page)
        this.setFieldSort(sort)
        this.setPageSize(itemsPerPage)
        this.setSort(sortDesc[0])
        this.fetch()
      },
      deep: true
    },
  },
  methods: {
    create() {
      this.$router.push({name: 'banner-create'})
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    hasPermCreate() {
      return hasPermision('CanAddBanner')
    },
    hasPermEditByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanEditBanner', codenames)
    },
    hasPermDeleteByOrgs(orgs) {
      const codenames = orgs ? orgs.map(x => x.org_codename) : []
      return hasPermissionsByOrgs('CanDeleteBanner', codenames)
    },
    clearSearch() {
      this.setTextSearch('')
      this.options.page = 1
      this.fetch()
    },
    textSearch(data) {
      this.filters.searchPhase = data
      this.timer = searchHandler(this.timer, clearTimeout, async () => {
        this.setTextSearch(
          this.filters.searchPhase ? this.filters.searchPhase.trim() : ''
        )
        this.options.page = 1
        await this.fetch()
      })
    },
    async fetchByTab(data) {
      this.resetSearchAndFilterData()
      if (this.tabItems[data] === tabEnum.SORT) {
        await this.fetchSortableList()
      }
      if (this.tabItems[data] === tabEnum.LIST) {
        await this.fetch()
      }
    },
    handleApplyFilter() {
      this.options.page = 1
    },
    statusText(status) {
      return this.statusLookup[status]
    },
    statusColor(status) {
      return this.colorsLookup[status]
    },
    dateFmt: date => convertDate(date),
    listBranch(orgs) {
      return orgs.map(org => org.org_codename.toUpperCase()).join(', ')
    },
    getType(type) {
      return typeText[type]
    },
    async handleSwitchStatusChange(event, id) {
      const isSuccess = await this.changeStatus({status: event, id: id})
      if (isSuccess) {
        this.showSuccessSnackbar(actionEnum.STATUS_UPDATED)
      } else {
        this.showFailSnackbar(actionEnum.STATUS_UPDATED)
      }
    },
    async actionPreview(item) {
      await this.setBannerPreview(item.id)
    },
    actionEdit(item) {
      this.resetState()
      this.$router.push({name: 'banner-edit', params: {id: item.id}})
    },
    setTabEvent(event) {
      if (event === 'act') {
        this.filters.searchPhase = ''
        this.setTextSearch(this.filters.searchPhase.trim())
        this.setTabStatus(0)
      } else {
        this.setTabStatus(1)
      }
      this.fetch()
      return
    },
    actionDelete(item) {
      this.deleteId = item.id
      this.isDelete = true
    },
    async confirmDialog() {
      const isSuccess = await this.deleteBanner(this.deleteId)
      this.deleteId = ''
      this.isDelete = false
      if (isSuccess) {
        this.fetch()
        this.showSuccessSnackbar(actionEnum.REMOVED)
      } else {
        this.showFailSnackbar(actionEnum.REMOVED)
      }
    },
    closeDialog() {
      this.deleteId = ''
      this.isDelete = false
    },
    closeEditOrder() {
      this.setShowEditOrder(false)
    },
    showSuccessSnackbar(action) {
      this.isCreateSuccess = true
      this.snackTitle = action
    },
    showFailSnackbar(action) {
      this.isCreateFail = true
      this.snackTitle = action
    },
    getContentByLang(contents, lang) {
      const contentsInLang = contents.filter(c => c.code === lang)
      if (contentsInLang.length > 0) {
        return contentsInLang[0].title
      }
      return ''
    },
    resetSearchAndFilterData() {
      this.options.page = 1
      this.componentKey++
      this.filters.searchPhase = ''
      this.resetSearchAndFilter()
    },
    changedSelectedBranch(value) {
      if (value.length > 0) {
        this.orgList.selected = []
        this.orgList.selected = convertSelectedData(
          value,
          this.orgList.all,
          this.orgList.selected
        )
        this.fetchByTab(this.tab)
      }
    },
    async saveEditOrder(data) {
      this.setShowEditOrder(false)
      const {oldOrderNo, newOrderNo} = data
      const newOrderList = swapArrayElements(
        this.sortableList,
        oldOrderNo - 1,
        newOrderNo - 1
      )
      await this.reorderBannersList(newOrderList)
      this.showSuccessSnackbar(actionEnum.ORDER_UPDATED)
    },
    ...mapActions('banner', [
      'changeStatus',
      'deleteBanner',
      'fetch',
      'fetchSortableList',
      'reorderBannersList',
      'resetList',
      'resetSearchAndFilter',
      'resetState',
      'setBannerId',
      'setBannerPreview',
      'setFieldSort',
      'setListOrgInfo',
      'setPage',
      'setPageSize',
      'setShowEditOrder',
      'setShowPreview',
      'setSort',
      'setTabStatus',
      'setTextSearch'
    ])
  },

  data() {
    return {
      pageName: 'banner',
      heightOptions: {
        header: 420,
        tableFooter: 59
      },
      windowSize: {
        x: 0,
        y: 0
      },
      isCreateSuccess: false,
      isCreateFail: false,
      snackTitle: '',
      componentKey: 0,
      selectedBranch: '',
      filters: {
        searchPhase: ''
      },
      actionEnumData: actionEnum,
      buttonConfirmText: 'Delete',
      subTitle:
        'Are you sure you want to remove this slide banner? This action will remove content in both Thai and English.',
      deleteId: '',
      isDelete: false,
      colorsLookup: {
        0: 'green',
        1: 'red',
        2: 'yellow accent-4'
      },
      statusLookup: {
        0: 'Active',
        1: 'Inactive',
        2: 'Waiting'
      },
      activeStatus: bannerStatusEnum.ACTIVE,
      inActiveStatus: bannerStatusEnum.INACTIVE,
      options: {},
      tab: 0,
      table: {
        page: 1,
        pageSize: 25,
        itemPerPages: [25, 50, 100],
        headers: [
          {
            text: 'Title',
            align: 'left',
            sortable: false,
            value: 'content',
            width: 180
          },
          {
            text: 'Branch',
            align: 'left',
            sortable: false,
            value: 'orgs',
            width: 105
          },
          {
            text: 'Slide Banner Type',
            align: 'left',
            sortable: false,
            value: 'type',
            width: 180
          },
          {
            text: 'Publish Status',
            align: 'center',
            value: 'is_publish',
            sortable: false,
            width: 140
          },
          {
            text: 'Publish Start Date/Time',
            align: 'left',
            value: 'period_start_date',
            sortable: false,
            width: 200
          },
          {
            text: 'Publish End Date/Time',
            align: 'left',
            value: 'period_end_date',
            sortable: false,
            width: 200
          },
          {
            text: 'Update Date/Time',
            align: 'left',
            sortable: false,
            value: 'update_date',
            width: 196
          },
          {
            text: 'Updated By',
            align: 'left',
            sortable: false,
            value: 'update_by_user',
            width: 162
          },
          {
            text: 'Status',
            align: 'center',
            sortable: false,
            value: 'status',
            width: 55
          },
          {
            text: 'Actions',
            align: 'left',
            sortable: false,
            value: 'actions',
            width: 140
          }
        ]
      },
      breadcrumbs: [
        {text: 'Dashboard', link: true, to: '/dashboard'},
        {
          text: 'Slide Banner',
          disabled: true,
          link: true,
          to: '/'
        }
      ]
    }
  }
}
</script>

<style scoped></style>
